@font-face {
  font-family: "Poppins";
  src: url('../public/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url('../public/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url('../public/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;

}


html,
body {
  padding: 0 !important;
  margin: 0;
  font-family: "Poppins", Arial, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.upload-image-style {
  border-radius: 10px;
}

.homePageNewAndEventCarouselContainer {
  padding-bottom: 30px;
}

.video-light-box div {
  z-index: 100000;
  background: rgba(0, 0, 0, 0.6) !important;
}

.dropbtn {
  /*background-color: #fff;*/
  /*color: white;*/
  /*padding: 16px;*/
  /*font-size: 16px;*/
  /*border: none;*/
}

.dropdown {
  /*position: relative;*/
  /*display: inline-block;*/
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid #dddddd;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.fade-slide-down {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

/*For respond.io if possible work*/
/*#respondio__growth_tool {*/
/*  bottom: 100px!important;*/
/*}*/

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dropdown-content a {
  color: #2c2c2c;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #fff;
}

.react-multiple-carousel__arrow {
  background: rgba(0, 0, 0, 0.8) !important;
}

.slick-item .slick-list .slick-track .slick-slide > div:nth-of-type(1) {
  padding: 20px;
}

.htmlContent a {
  color: #3462f4;
}

.htmlContent a:hover {
  text-decoration: underline;
}

.htmlContent img{
  width: 100%;
  height: 100%;
}

.htmlContent h1{
 font-size: 1.875rem;
}
.htmlContent h2{
 font-size: 1.5rem;
}
.htmlContent h3{
 font-size: 1.125rem;
}
/*.htmlContent table {*/
/*  max-width: 100%;*/
/*}*/
.htmlContent .table-container-guide{
  overflow-x: auto;
}

  .adsbygoogle, #aswift_1_host  {
    display: block !important;
  }

  @media(max-width: 480px) { 
    .adsbygoogle, #aswift_1_host {
      display: none !important; 
    } 
  }
