.fade {
  &:not(.show) {
    opacity: 1;
  }
}

:focus-visible {
  outline: none;
}

body {
  overflow: auto;
  padding-right: 0px !important;
}
